
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class AddNewProviderModal extends Vue {

    @Prop() saveCallback: (updatedModel: OM.CreateProviderForGarmentVM) => void;

    model: OM.CreateProviderForGarmentVM = new OM.CreateProviderForGarmentVM();
    countries: OM.TextIdentifier[] = [];

    created() {
        ElectricityEmissionClient.getCountries(true)
        .then(x => {
            this.countries = x;
        })
    }

    save() {
        this.saveCallback(this.model);
    }
}
