
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ProviderClient } from '@/services/Services';
import AddNewProviderModal from './addNewProviderModal.vue';
import * as OM from '@/Model';

@Options({})
export default class ChooseProviderModal extends Vue {

    @Prop() availableProviders: OM.EditGarmentProductionsVM[];
    @Prop() estimationIdentifier: string;
    @Prop() layer: string;
    @Prop() productionPhase: string;
    @Prop() provider: OM.TextIdentifier;
    @Prop() garmentId: string;
    @Prop() saveCallback: (updatedModel: OM.EditProvidersInvolvedVM) => void;

    localAvailableProvidersTextID: OM.TextIdentifier[] = [];
    model: OM.EditProvidersInvolvedVM = new OM.EditProvidersInvolvedVM();

    created() {
        this.availableProviders.forEach(element => {
            var localItem = new OM.TextIdentifier();
            localItem.identifier = element.provider.identifier;
            localItem.text = element.provider.text;
            this.localAvailableProvidersTextID.push(localItem);
        });

        this.model.estimationIdentifier = this.estimationIdentifier;
        this.model.fabricName = this.layer;
        this.model.productionPhase = this.productionPhase;
        this.model.provider = this.provider;
    }

    get disabled(){
        return !this.model.provider.identifier;
    }

    addNewProvider() {
        this.$opModal.show(AddNewProviderModal, {
            saveCallback: ((createProviderModel: OM.CreateProviderForGarmentVM) => {
                createProviderModel.garmentIdentifier = this.garmentId;

                ProviderClient.createAndInitializeProvider(createProviderModel)
                .then(x => {
                    var newTextIdForList = new OM.TextIdentifier();
                    newTextIdForList.identifier = x;
                    newTextIdForList.text = createProviderModel.providerName;
                    this.localAvailableProvidersTextID.push(newTextIdForList);
                    this.model.provider.identifier = x;

                    this.$opModal.closeLast();
                })
            })
        })
    }

    save() {
        var textIdProvider = new OM.TextIdentifier();
        textIdProvider.identifier = this.model.provider.identifier;
        textIdProvider.text = this.localAvailableProvidersTextID.filter(x => x.identifier == textIdProvider.identifier)[0].text;
        this.model.provider = textIdProvider;

        this.saveCallback(this.model);
    }
}
