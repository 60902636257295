
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EstimationClient, GarmentDefaultDataClient, ProviderClient } from '@/services/Services';
import ChooseProviderModal from '../../../modals/chooseProviderModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Providers extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentConfigurationIdentifier: string;
    @Prop() saveToGarmentConfiguration: boolean;
    
    garmentId: string = "";
    providersInvolved: {[key : string]: OM.ProviderProductionPhase[]} = {};
    providersAvailable: OM.EditGarmentProductionsVM[] = [];

    loaded: boolean = false;

    created() {
        this.init();
    }
    
    init() {
        Promise.all([
            store.state.estimationClient.getProvidersInvolved(this.estimationIdentifier),
            ProviderClient.getAllByCompanyAndGarment(this.garmentConfigurationIdentifier),
            EstimationClient.getGarmentConfigurationIdentifier(this.estimationIdentifier)
        ])
        .then( xs => {
            this.providersInvolved = xs[0];
            this.providersAvailable = xs[1];
            this.garmentId = xs[2];

            this.loaded = true;
        })
    }

    editProviderPerPhase(item: OM.ProviderProductionPhase, layer: string) {
        this.$opModal.show(ChooseProviderModal, {
            availableProviders: this.providersAvailable,
            estimationIdentifier: this.estimationIdentifier,
            layer: layer,
            productionPhase: item.productionPhase,
            provider: item.provider,
            garmentId: this.garmentId,
            saveCallback: ((updateModel: OM.EditProvidersInvolvedVM) => {
                store.state.estimationClient.editProvidersInvolved(updateModel)
                .then(x => {
                    this.init();
                    this.$opModal.closeLast();
                })
            })
        })
    }

    next() {
        if(this.saveToGarmentConfiguration){
            alert("finito, esci");
        } else {
            this.$router.push("/calculator/" + this.estimationIdentifier + "/summary");
        }
    }

}
